<template>
  <modal
    name="modal--tc"
    transition="nice-modal-fade"
    :adaptive="true"
    :reset="true"
    :scrollable="true"
    width="65%"
    height="auto"
    class="modal--installment"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('fintechInstallment.modalTOS.title') }}
      </div>
      <SectionLoader :show="isLoading" />
      <section class="modal--container">
        <div class="title--text" v-if="$mq !== 'xs'">
          <h4>{{ $t('fintechInstallment.modalTOS.title') }}</h4>
          <hr />
        </div>
        <agreement-content-cicilsewa @clicked="onVisible" v-if="company === 'cicilsewa'" />
        <agreement-content-gradana @clicked="onVisible" v-else-if="company === 'gradana'" />
        <!-- <SectionLoader :show="isLoading"/> -->
        <hr />
        <div class="custom-control custom-checkbox">
          <input
            :disabled="isLoading"
            v-model="isAgree"
            type="checkbox"
            class="custom-control-input"
            id="agreementTC"
          />
          <label class="custom-control-label" for="agreementTC">
            {{
              company === 'cicilsewa'
                ? $t('fintechInstallment.modalTOS.TOSCicilSewa')
                : $t('fintechInstallment.modalTOS.TOSGradana')
            }}
          </label>
        </div>
        <button
          :disabled="!isAgree || isLoading"
          @click="postRequest"
          :class="{ running: isLoading }"
          class="btn btn--cta main--btn"
        >
          {{ $t('fintechInstallment.modalTOS.btn') }}
          <span class="ld ld-ring ld-spin"></span>
        </button>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>
<script>
import { mapState } from 'vuex';

const SectionLoader = () => import('@/components/content-loading/section-loading');
const AgreementContentCicilsewa = () => import('@/components/installment/content-TC-cicilsewa');
const AgreementContentGradana = () => import('@/components/installment/content-TC-gradana');
export default {
  components: {
    SectionLoader,
    AgreementContentCicilsewa,
    AgreementContentGradana,
  },
  data() {
    return {
      isLoading: false,
      isAgree: false,
      isEnable: false,
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.v2.fintechInstallment.company,
    }),
  },
  methods: {
    close() {
      this.$modal.hide('modal--tc');
    },
    onVisible(e) {
      this.isEnable = e;
    },
    async postRequest() {
      try {
        this.isLoading = true;
        const result = await this.$store.dispatch('v2/fintechInstallment/postRequest');
        // eslint-disable-next-line no-undef
        fbq('track', 'submitInstallment');
        if (result) {
          this.$swal({
            title: 'Sukses!',
            text: 'Pengajuan cicilan telah berhasil dikirim.',
            type: 'success',
            allowOutsideClick: false,
          }).then((response) => {
            if (response.value) this.$router.replace('/inbox');
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
